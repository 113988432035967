var columns = [{
  title: "User",
  dataIndex: "nickname",
  key: "nickname"
}, {
  title: "Hood Id",
  dataIndex: "hoodid",
  key: "hoodid"
}, {
  title: "Pump比例",
  dataIndex: "pumpradio",
  key: "pumpradio",
  scopedSlots: {
    customRender: "pumpradio"
  }
}, {
  title: "Web2总收益",
  dataIndex: "web2amount",
  key: "web2amount",
  scopedSlots: {
    customRender: "web2amount"
  }
}, {
  title: "web2 Pump",
  dataIndex: "web2pump",
  key: "web2pump",
  scopedSlots: {
    customRender: "web2pump"
  }
}, {
  title: "Free Pump",
  dataIndex: "freepump",
  key: "freepump",
  scopedSlots: {
    customRender: "freepump"
  }
}, {
  title: "总Pump资金",
  dataIndex: "pumpamount",
  key: "pumpamount",
  scopedSlots: {
    customRender: "pumpamount"
  }
}, {
  title: "Tx hash",
  dataIndex: "txhash",
  key: "txhash",
  width: 200,
  scopedSlots: {
    customRender: "txhash"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  scopedSlots: {
    customRender: "action"
  }
}];
export { columns };